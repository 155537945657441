<template>
  <div class="flex flex-col w-0 flex-1 overflow-hidden">
    <div class="relative flex-shrink-0 flex h-16 bg-white dark:bg-gray-750 shadow z-10">
      <!-- Open mobile sidebar button -->
      <button type="button" class="px-4 border-r border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden" @click="toggleSidebar(true)">
        <span class="sr-only">Open sidebar</span>
        <MenuIcon class="w-6 h-6" />
      </button>

      <!-- Topbar search form -->
      <div class="flex-1 px-4 flex justify-between">

        <slot name="beforeSearch" />

        <template v-if="filter">
          <div class="flex items-center mr-4">
            <button @click="showFilter = !showFilter" type="button" class="text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-770 px-2 py-2 rounded-full">
              <FilterIcon class="w-6 h-6" />
            </button>
          </div>
        </template>
        
        <template v-if="search">
          <div class="flex-1 flex">
            <form @submit.prevent class="w-full flex md:ml-0">
              <label for="search-field" class="sr-only">Search</label>
              <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none pt-1 dark:text-gray-300">
                  <SearchIcon class="w-5 h-5" />
                </div>
                <input id="search-field" class="bg-transparent block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 dark:text-gray-300 dark:text-gray-300 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                       v-model="searchText"
                       placeholder="Search"
                       type="search"
                       name="q"
                       @keydown.enter="setSearch"
                />
              </div>
            </form>
          </div>
        </template>

        <TopBarNavigation v-if="links" :links="links" />

        <slot name="topbar" />
      </div>

    </div>

    <template v-if="filter">
      <div class="relative w-full z-[9]" :class="showFilter ? 'max-h-full' : 'max-h-0 overflow-hidden'">
        <transition name="filter-tr" enter-from-class="filter-from" enter-to-class="folter-to" leave-from-class="filter-leave-from" leave-to-class="filter-leave-to">
            <div class="bg-white dark:bg-gray-750 w-full px-5 py-3 filter shadow">
              <div class="flex w-full">
                <div class="flex flex-wrap h-full gap-y-3 items-center justify-center">
                  <slot name="filter" />
                </div>
              </div>
            </div>
        </transition>
      </div>
    </template>

    <main class="flex-1 relative overflow-y-auto focus:outline-none dark:bg-gray-800">
      <div class="py-6 outline-none" ref="pageContent" tabindex="-1" :style="`zoom: ${zoom}`">
        <div id="pageContent" class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 page-content">
          <!-- Replace with your content -->
          <div class="py-4">
            <template v-if="dataLoading">
              <div class="w-full flex items-center justify-center py-6">
                <Loading />
              </div>
            </template>
            <template v-else>
              <slot name="content" />
            </template>

          </div>
          <!-- /End replace -->
        </div>
        
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Search from "@/services/Helpers/search";

import { defineAsyncComponent } from 'vue'

const TopBarNavigation = defineAsyncComponent(() =>
  import('@/components/Components/TopBarNavigation'))
const Loading = defineAsyncComponent(() =>
  import('@/components/Components/Loading'))

import {
  MenuIcon,
  FilterIcon,
  SearchIcon,
} from "@heroicons/vue/outline";

export default {
  name: "Content",
  components: {
    TopBarNavigation,
    Loading,
    MenuIcon,
    FilterIcon,
    SearchIcon,
  },
  data() {
    return {
      searchText: "",
      showFilter: false,
    }
  },
  computed: {
    ...mapGetters({
      dataLoading: "dataLoading",
    })
  },
  props: {
    links: {
      type: Array,
      default: null
    },
    search: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Boolean,
      default: false
    },
    showFilterDefault: {
      type: Boolean,
      default: false
    },
    zoom: {
      type: String,
      default: '100%'
    },
  },
  mounted() {
    const q = Search.getSearchQuery();
    if (q) {
      this.searchText = q;
    }
    if (this.showFilterDefault) {
      this.showFilter = this.showFilterDefault
    }

    this.$nextTick(() => {
      if(this.$refs && this.$refs.pageContent) {
        this.$refs.pageContent.focus()
      }
    });
  },
  methods: {
    setSearch: function (event) {
      event.preventDefault();
      this.$store.dispatch('changeSearch', this.searchText)
    },
    toggleSidebar(value) {
      this.$store.dispatch('setSidebarOpen', value)
    },
  },
}
</script>

<style scoped>
.filter {
  transition: all 0.35s ease;
}
.filter-from {
  opacity: 0;
  transform: translate(0, -100%);
}
.filter-to {
  transform: translate(0, 0);
  opacity: 1;
}
.filter-leave-from {
  opacity: 1;
  transform: translate(0, 0);
}
.filter-leave-to {
  transform: translate(0, -100%);
  opacity: 0;
}

</style>